export default {
  data() {
    return {
      flag2: false,
      searchData1: {},
      searchData2: {},
      loading2: false,
      lst: [],
      all: 0,
      pi: 1,
      ps: 10,
    }
  },
  methods: {
    open2() {
      if (!this.formd.BtmsOrderId) {
        this.$Message.warning("请先选委托单")
        return
      }
      if (this.action == "fast") {
        this.items_fast.push({
          ItemId: 0,
          Type: 1,
          SubType: 1,
          Name: "",
          Qty: 1,
          ReceiveDate: new Date().format("yyyy-MM-dd"),
        });
      } else {
        this.flag2 = true;
        this.reqGoods();
      }
    },
    close2() {
      this.flag2 = false;
    },
    reqGoods() {
      let params = Object.assign(
        {
          pageIndex: this.pi,
          pageSize: this.ps,
          OrderId: this.formd.BtmsOrderId,
        },
        this.searchData2
      );
      this.loading2 = true;
      this.$axios
        .get("/Api/WmsGoods/QueryPg", { params: params })
        .then((res) => {
          this.loading2 = false;
          if (res.result) {
            this.all = res.data.RecordCount;
            let lst = [];
            for (let i in res.data.DataSet) {
              let a = res.data.DataSet[i];
              a.imgCount = 0;
              if (a.Img1) {
                a.imgCount++;
              }
              if (a.Img2) {
                a.imgCount++;
              }
              if (a.Img3) {
                a.imgCount++;
              }
              if (a.Img4) {
                a.imgCount++;
              }
              if (a.Img5) {
                a.imgCount++;
              }
              lst.push(a);
            }
            this.lst = lst;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading2 = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pi = 1;
      this.reqGoods();
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqGoods();
    },
    onAddItem(i) {
      let goods = this.lst[i];
      for (let item of this.items) {
        console.log("SubType", goods.SubType)
        if (goods.SubType == 1) { // 样品
          if (goods.Id == item.GoodsId) {
            this.$Message.warning(`明细已存在样品“${item.GoodsNo}”`)
            return
          }
        } else if (goods.SubType == 99) { // 其他物料
          if (goods.Id == item.GoodsId) {
            this.$Message.warning(`明细已存在物料“${goods.Name}”`)
            return
          }
        }
      }
      this.items.push({
        ItemId: this.lockedItemId || goods.ItemId,
        Type: goods.Type,
        SubType: goods.SubType,
        Name: goods.Name,
        Code: goods.Code,
        GoodsId: goods.Id,
        GoodsNo: goods.GoodsNo,
        Qty: 1,
        Img1: goods.Img1,
        Img2: goods.Img2,
        Img3: goods.Img3,
        Img4: goods.Img4,
        Img5: goods.Img5,
        Remark: "",
      });
    },
    viewImgs(i) {
      let imgs = [];
      if (this.lst[i].Img1) imgs.push(this.lst[i].Img1);
      if (this.lst[i].Img2) imgs.push(this.lst[i].Img2);
      if (this.lst[i].Img3) imgs.push(this.lst[i].Img3);
      if (this.lst[i].Img4) imgs.push(this.lst[i].Img4);
      if (this.lst[i].Img5) imgs.push(this.lst[i].Img5);
      this.$root.$emit("view-imgs", imgs);
    },
  }
}